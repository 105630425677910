import _isDOMElement from "./isDOMElement.js";
var exports = {};
const isDOMElement = _isDOMElement;
/**
 * Find a DOM element.
 *
 * @param {Node|string} element
 * @returns {Node|null}
 */

function findDOMElement(element, context) {
  if (context === void 0) {
    context = document;
  }

  if (typeof element === "string") {
    return context.querySelector(element);
  }

  if (isDOMElement(element)) {
    return element;
  }

  return null;
}

exports = findDOMElement;
export default exports;